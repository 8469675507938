import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय ९ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय ९ वा' num='09'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे गणपती सरस्वती । देवीसही निमंत्रणे होती । आवश्यक उपस्थिती ॥ १ ॥
नाही पुसणार नुसता । नवरात्रात तेवढ्यापुरता । केवळ मतलबापुरता । केवळ नवसापुरता ॥ २ ॥
तेवढ्यापुरते नसावे हे जाणतो । जेणे वारंवार बोलावतो । नित्य तिची स्मृती ठेवतो । नित्य तिला पूजतो ॥ ३ ॥
छत्रपती केवढा शूर । आधार होता देवीवर । भवानी मातेवर । तिच्या कृपेने झाला थोर ॥ ४ ॥
एकामुळे प्रेरणा मिळते । दुसरा कार्यरत होतो । श्रद्धेवर खेळ चालतो । श्रद्धेनेच जीव तरतो ॥ ५ ॥
देवी ! तुझी रूपे वेगळी । खूण पटवते वेळोवेळी । आळवल्यानेच येते जवळी । रक्षिते आपत्काळी ॥ ६ ॥
हे कोल्हापुरचे देवी । माझ्या स्थानावर धाव घ्यावी । सरस्वती ग्रंथ लिहवी । त्यात तुझे सहाय्य होई ॥ ७ ॥
दत्तात्रेया, तुलाही करतो आमंत्रण । लाभावे तुमचे चरण । सुरस व्हावे ग्रंथलेखन । नाही तुमच्या सहाय्यावाचून ॥ ८ ॥
भक्तांना आनंद मिळावा । गणामहाराज पडतो पाया । असावी तुमची कृपाछाया । तेणेच उद्धरणे होते जीवा ॥ ९ ॥
जैसे माझे मागणे होते । तैसेच कीर्तनकाराचे होते । गोविंदबुवांचे ऐसेच होते । ईश्वरीकृपा याचत होते ॥ १० ॥
आनंद देण्यास कीर्तन करायचे । भक्तीमार्गास लावायचे । गावोगावी हिंडायचे । वाहन करावे लागायचे ॥ ११ ॥
हल्ली जशी मोटार स्कूटर । पूर्वी व्हावे लागे घोडेस्वार । कालमानाप्रमाणे होणार । परी वाहन साधन लागणार ॥ १२ ॥
एकदा मोट्यांच्या शिवमंदीरी । शेगावी गोविंदबुवांची स्वारी । ते उत्कृष्ट कीर्तन करी । सर्वांस तल्लीन करी ॥ १३ ॥
गोविंदबुवा शेगावी आले । घोडे बरोबर आणले । घोडे होते द्वाड भले । मधुन मधुन उधळे ॥ १४ ॥
वाहन असावे तंदुरुस्त । जेणे चालक चित्ती स्वस्थ । नसते प्रत्येकाच्या नशिबात । गोविंदबुवा एक त्यात ॥ १५ ॥
गोविंदबुवांचे नशिब ऐसे । द्वाड घोडे त्यांचे असे । स्वस्थतेचे नाव नसे । गोविंदबुवाही अस्वस्थ असे ॥ १६ ॥
दुसर्‍यास करण्या तल्लीन । आधी स्वतः व्हावे लागते तल्लीन । जाणत होते मनोमन । सुयोग न गुरूकृपेवाचून ॥ १७ ॥
कालावधी निश्चित असतो । तो कुणी न बदलू शकतो । संतकृपेने सुसह्य होतो । जो प्रारब्धभोग असतो ॥ १८ ॥
गोविंदबुवा विसरले साखळीला । उपयुक्त घोड्यास बांधण्याला । चर्‍हाटे दोरखंडाने बांधले त्याला । म्हणे अनुभवीन स्वस्थतेला ॥ १९ ॥
घोडे खिंकाळत होते । उसळणे चालू होते । गोविंदबुवा कीर्तन करत होते । लक्ष तेथे नव्हते ॥ २० ॥
कीर्तन संपले असता । वाड्यात झाले जाता । निवांतपणा नव्हता चित्ता । घोडा दिसू लागला ॥ २१ ॥
घोडा न राहे स्वस्थ । व्याधी उपाधी ग्रस्त । दुसर्‍यास करी त्रस्त । गोविंदबुवा न कधी स्वस्थ ॥ २२ ॥
घोडा मुळचा द्वाड फार । लोकांना लाथा मारणार । घोड्याच्या आवाजावर । गोविंदबुवा कैसा झोपणार ? ॥ २३ ॥
दुष्टपणा असे एकाचा । तो दूर करण्याचा । स्वभाव दुसर्‍यांचा । हा खेळ प्रारब्ध भोगाचा ॥ २४ ॥
हा खेळ ऐसाच चाले । सामान्यांचे थट्टा चाळे । संत दूर करती योगबळे । समर्थांचे ऐसेच चाले ॥ २५ ॥
समर्थ रात्री उठले । मुखे पुटपुटणे चाले । घोड्याकडे निघाले । खिंकाळणे कमी झाले ॥ २६ ॥
जे गण गण गणात बोते जपणार । एक एक क्षण त्यात रमणार । निवांतपणा न मिळणार । दुसर्‍यासाठीच जगणार ॥ २७ ॥
परोपकारी जगतसे । स्वस्थतेचे नाव नसे । स्वतःस पार विसरतसे । समर्थांचे जगणे ऐसे ॥ २८ ॥
विधात्याच्या ईच्छेनुसार । समर्थ जगणार । परी कृती जी होणार । ती चमत्कृतीच ठरणार ॥ २९ ॥
घोडा खिंकाळत होता । लाथा झाडत होता । सांगण्या त्याच्या व्यथा । ऐसे करत होता ॥ ३० ॥
महाराज उठले । घोड्याजवळ आले । पाठीवर चुचकारले । त्याच्या व्यथेस जाणले ॥ ३१ ॥
घोड्यास जे स्पर्श झाले । ते त्याला जाणवले । मायेचा माणुस वाटले । मायेचे भरते आले ॥ ३२ ॥
घोडा जोरात खिंकाळणार । जो सदा लाथा झाडणार । कोण न त्याला घाबरणार ? । अहो मालकही न आवरणार ॥ ३३ ॥
समर्थ तत्वे जाणणार । प्राणीमात्रांवर दया करणार । त्यांचे उणे दूर करणार । न उगाळित बसणार ॥ ३४ ॥
समर्थे घोड्याच्या पाठीवर । हळुवार हात फिरवल्यावर । वेगळा स्पर्श जाणवल्यावर । घोडा आला भानावर ॥ ३५ ॥
महाराज बसले । खाली वाकले । चौखुरात आडवे झाले । खिंकाळणे दूर झाले ॥ ३६ ॥
जनावरांनाही मन असते । सर्वांनाच न पटते । समर्थांस अवगत होते । कृतीतून पटवत होते ॥ ३७ ॥
पटवणारा योग्यच लागतो । अयोग्य येडा गबाळा ठरतो । जो प्रभावी असतो । तोच दुसर्‍यावर ठसवतो ॥ ३८ ॥
करणी (कृती) प्रभावी लागते । समर्थांचे तसेच होते । घोड्याने जाणले होते । तेणे शांत झाले होते ॥ ३९ ॥
समर्थे नुसता स्पर्श केला । द्वाड घोडा शांत झाला । समर्थ स्पर्श प्रभावी ठरला । घोड्यावरचा बट्टा टळला ॥ ४० ॥
ज्याने देहाच्या रोमारोमात । नामजप ठसवला त्यात । त्याच्या एकेक कृतीत । प्रभावच दिसतात ॥ ४१ ॥
जे गोविंदबुवास वाणीने । तसेच सहवासाने । घोड्यास शांत करणे । प्रयत्न विफल ठरणे ॥ ४२ ॥
तेच समर्थांनी कृतीने । हळुवार स्पर्शाने । केले सहजपणे । अगदी बिनबोभाटपणे ॥ ४३ ॥
द्वाड घोडा शांत झाला । चौखुरांचा सहारा दिला । ज्याच्यावर जीव जडला । तो ऐसाच येणार अनुभवाला ॥ ४४ ॥
जो स्वतःहून सहारा देणार । तो काय लाथा मारणार ? । जो जीव मनापासून कर्मे करणार । तो आनंदानेच वागणार ॥ ४५ ॥
इकडे गोविंदबुवा पहुडलेले । घोड्याचे खिंकाळणे थांबले । ते विस्मयचकित झाले । पहाण्यास अधीर झाले ॥ ४६ ॥
ते घोड्याजवळ आले । आश्चर्यचकित झाले । कीर्तन धडे घोकलेले । म्हणे सर्व वाया गेले ॥ ४७ ॥
मनोमनी ते पटले । स्वतःची चूक समजले । कोरडे उपदेश केलेले । कधी न पचनी पडले ॥ ४८ ॥
समर्थ चरण धरले । कंठ दाटून आले । अश्रू नयनातून ओघळले । अंतरंग बोलके झाले ॥ ४९ ॥
गोविंदबुवा समर्थांस वदले <br/>
<br/>
व्यर्थ हिंडलो, व्यर्थ फिरलो ।			कीर्तनाचे कारण बनलो ।<br/>
कुटुंबाचे पोषण केले ।				पुस्तकी ज्ञान अनुसरले । <br/>
ज्या न्याहारीस जवळ केले ।			तेणे कुणाचे न पोट भरले ।<br/>
ह्या जन्मीचेच अर्धवट झाले । 		पुढच्या जन्मीचे काय बोले ।<br/>
पोटभरीचे न कधी झाले । 			अतृप्तच मन राहिले ।<br/>
ज्याने घोडे न शांत केले । 			गोविंदबुवास काय कळले ? ।<br/>
दुसर्‍याची व्यथा न जाणले । 			कोरडे निकामे इलाज केले ।<br/>
कीर्तनाचे धडे घोकलेले । 			व्यवहारी न उपयुक्त ठरले ।<br/>
नुसतेच पांडित्य झाले । 				स्वानुभव न पदरी पडले ।<br/>
पश्चातापाचे जीणे झाले । 			तेणे समर्थ चरण धरले ।<br/>
कृपाशिष मी मागीतले ।				तुम्हीच हित करा झाले ।<br/>
<br/>
जेव्हा पश्चाताप होणार । तेव्हा ऐसे उद्गार निघणार । कुणाचा तरी आधार घेणार । तेणे जीवन तरणार ॥ ५० ॥
समर्थे केले त्यास जवळ । ज्ञानी बनविले तत्काळ । सार सांगितले सकळ । म्हणे श्रद्धा असू दे अढळ ॥ ५१ ॥
समर्थे उपदेश केला कृतीतुनी । नच नुसत्या वाणीतुनी । गोविंदबुवास जवळ करूनी । नेले त्यास उद्धरूनी ॥ ५२ ॥
समर्थे त्यास उपदेशीले । पदरचे कधी सांगू नये । महत्वाचे विसरू नये । दुसर्‍याचे दुःख जाणावे । त्याचे हलके करावे ॥ ५३ ॥
नको नुसते ज्ञान पुढ्यात । असावे कर्तव्य कर्मात । तेच उपयुक्त प्रपंचात । परी अलिप्त वर्तनात ॥ ५४ ॥
गण गण गणात बोते । रहस्य झाले सांगते । जीवाचे ब्रह्माशी नाते । सुट्टा न पडणार त्याते ॥ ५५ ॥
जीव ब्रह्मापासून नसे वेगळा । त्याचाच अंश न कळे सकळा । चराचरात जो वास भरला । तो त्याचाच आहे झाला ॥ ५६ ॥
समर्थांस सर्व कळे । दुसर्‍यांच्या मनातले । अगदी अंतरंगातले । अंतर्ज्ञाने पटवले ॥ ५७ ॥
स्वार्थी जन जे असतात । ते भयभीत प्रसंगात । वाटेलते नवस करतात । देवास साकडे घालतात ॥ ५८ ॥
कार्य होता विसरतात । कधी चाल ढकल करतात । मूळचा स्वभाव आचरतात । सोयीस्कर पणे वागतात ॥ ५९ ॥
बाळापुरचे ब्राह्मण । काही हेतू मनात ठेवून । आले घ्याया दर्शन । म्हणे घ्यावे हेतू साधून ॥ ६० ॥
उगाच न कोणी येई दर्शनाला । जोतो हपापलेला । स्वतःच्या स्वार्थाला । नच परमार्थाला ॥ ६१ ॥
केवळ जो स्वार्थ जाणणार । तो संधी साधणार । तो मतलबी असणार । तो ऐसेच वागणार ॥ ६२ ॥
जागे न करावे लागत जागृतास । माहित होते समर्थांस । परी काणा डोळा करणार्‍यांस । आणत होते भानास ॥ ६३ ॥
जे निद्रेत असतात । त्यांनाच सावध करतात । जे त्यांच्याकडे येतात । ते त्यांचेच होऊन राहातात ॥ ६४ ॥
केवढा मनाचा मोठेपणा । दुसर्‍यास आपलेसे करताना । न दुःख यातना । अगदी सहजपणा ॥ ६५ ॥
तो न शिकवण्याने येतो । उपजत असावा लागतो । कधी हृदयस्पर्शी जाणीवेतून होतो । तोच वेळोवेळी दिसतो ॥ ६६ ॥
दुसर्‍यास भानावर आणणे । हेच समर्थांचे वागणे । आपलेपणाने जगणे । स्वभावात ठामपणे ॥ ६७ ॥
लोक स्वतःच गाफिल । ठरविती योग्यास गाफिल । जे ऐसे वागतील । ते फजित पावतील ॥ ६८ ॥
महाराजांचे मौन व्रत । दुसर्‍यास जागे करण्यात । कान‌उघाडणी करण्यात । सदा असायचे कार्यरत ॥ ६९ ॥
ह्या समर्थांची ऐशी कृती । सदा जन जागृती । ह्याच्या साठीच झटती । स्वतःचे कर्तव्य समजती ॥ ७० ॥
ब्राह्मण बाळापुरचे । नवस बोलले गांजाचे । काम होता त्यांचे । विसरणे झाले नवसाचे ॥ ७१ ॥
नवस करणे माहित असलेले । फेडणे न शिकलेले । काम होता ते विसरले । जे नेहमीचे तेच झाले ॥ ७२ ॥
जे तत्व न जाणणार । कैसी चूक लक्षात येणार ? । नुसतेच नवस बोलणार । फेडण्याचे विसरणार ॥ ७३ ॥
येथेही त्यांचे असेच झाले । नवस फेडणे विसरले । उपरण्यास गाठ मारते झाले । पुढच्या खेपेस होईल भले ॥ ७४ ॥
पुन्हा पुन्हा दर्शनास । विसरले नवस फेडण्यास । कळले समर्थांस । आणले निदर्शनास ॥ ७५ ॥
हा नंगा बाबा काय समजणार ? । ह्याला काय ज्ञान असणार ? । ऐसे ब्राह्मण समजणार । परी वेगळेच अनुभवणार ॥ ७६ ॥
समर्थांनी सांगीतले त्यास । नका विसरू नवसास । पुढील खेपेस आणा गांजास । नुसत्या गाठी नको उपरण्यास ॥ ७७ ॥
ब्राह्मण चकित झाले । समर्थ चरण धरले । दुसर्‍याचे महत्व पटले । तेणे ऐसे वागले ॥ ७८ ॥
चरण धरा न सांगावे लागत । सहज धरले जातात । सारे अवलंबे कृतीत । कृतीच्या प्रभावात ॥ ७९ ॥
तळमळीने करता स्मरण । समर्थ देती दर्शन । जात पात विसरून । त्याचेच होती राहुन ॥ ८० ॥
ऐसे करावे स्मरण । काय न होणार दर्शन । ज्याला न होणार दर्शन । उपासनेत न्यूनपण ॥ ८१ ॥
ज्याला होणार दर्शन । त्याचीच भक्ती मनोमन । अंतरंग जाणुन । समर्थ देती दर्शन ॥ ८२ ॥
नुसती करू नये उपासना । दर्शनाच्या संकल्पाविना । जे जे मिळते दर्शना । न पटे अनुभवाविना ॥ ८३ ॥
दर्शनात सारे मिळते । जे भेटीत मिळते । ते पत्रोत्तरात न मिळते । अनुभवाने सारे पटते ॥ ८४ ॥
जैसी ईच्छा असते मनास । तैसे होते आचरणास । तेच अनुभवास फळास । आनंदाच्या ईच्छेस आनंदास ॥ ८५ ॥
उगाच दोष नको ईश्वरास । शुद्ध हेतू ज्याच्या मनास । भाग्य येते उदयास । तोच सत्पात्र दर्शनास ॥ ८६ ॥
अहो द्यावे कुणास ? । द्यावे सत्पात्रास । माहित असते ईश्वरास । कैसा विसरणार तत्वास ? ॥ ८७ ॥
चूक न ईश्वराची होणार । तो सत्पात्रच शोधणार । योग्य वेळेस देणार । नाही फुकटचे देणार ॥ ८८ ॥
बाळापुरचा बाळकृष्ण । जाणत होता मनोमन । रामदासांची सेवा करून । जगत होता जीवन ॥ ८९ ॥
पुतळाबाई अर्धांगिनी । पतीव्रता मनोमनी । दिसत होते कृतीतुनी । उकलले जाई अंतरंगातुनी ॥ ९० ॥
पौष वद्य नवमीस । बाळकृष्ण सोडे बाळापुरास । कांता बरोबर वारीस । जाई सज्जनगडास ॥ ९१ ॥
चिपळ्या घेऊन हातात । तल्लीन होई भजनात । पत्नीचीहि असे साथ । झांजा घेऊनी हातात ॥ ९२ ॥
रघुपती राघव राजाराम । मुखे निघे राम नाम । सोडून एक एक ग्राम । गाठे पायी मुक्काम ॥ ९३ ॥
शेगाव, खामगाव, मेहेकर । जालनापुर, जांबनगर । पायीच कापतसे अंतर । निश्चयाच्या बळावर ॥ ९४ ॥
जांब-ठिकाण । समर्थांचे जन्मस्थान । तीन दिवस मुक्काम करून । जाई पुढिल गावी निघून ॥ ९५ ॥
पुढे बीड, अंबेजोगाई । मोहोरी, डोमगावी । समर्थांचे स्मरण होई । पुढची पाऊले टाकली जाई ॥ ९६ ॥
नरसिंगपुर, पंढरपुर । नाते-पोते, शिंगणापुर । वाई आणि सातार । गडाच्या पायथ्याशी येणार ॥ ९७ ॥
माघ वद्य प्रतीपदेस । यावे त्याने सज्जनगडास । राहुन उत्सवास । प्राप्त करावे आनंदास ॥ ९८ ॥
बरीच वर्षे क्रम चालला । प्रती वर्षी जाई उत्सवाला । साठीच्या वयाला । देह थकत चालला ॥ ९९ ॥
माघ वद्य एकादशीला । समाधीपाशी बैसला । दुःखाश्रु नयनाला । भाव व्यक्त केला ॥ १०० ॥
हे सद्गुरूनाथा समर्था । वय वर्षे साठा । देह थकत चालला आता । कैसी पायी वारी आता ? ॥ १०१ ॥
वाहनात जरी बसून । येऊ सज्जनगडा लागून । तेही दिसते कठीण । भली मोठी अडचण ॥ १०२ ॥
आता वारी न होणार । आता पडणार अंतर । आता थकले शरीर । हिच मजला हुरहुर ॥ १०३ ॥
आपण सर्व जाणता । भक्त जवळ करता । तूच मार्ग काढ आता । तूच ठरणार त्राता ॥ १०४ ॥
बाळकृष्णानेच रामदास आळवावा । रामदासे भाव ओळखावा । त्यावर कृपावर्षाव करावा । उत्प्रेक्षाच योग ठरावा ॥ १०५ ॥
केली प्रार्थना मनोमन । झोपी जाता बाळकृष्ण । पहाटेस स्वप्न । रामदास देती दर्शन ॥ १०६ ॥
रामदास वदले बाळकृष्णास । आता शरीरे थकलास । पायी न करावे वारीस । नको क्लेश शरीरास ॥ १०७ ॥
भाव तुझ्या अंतरंगास । तो समजला आम्हांस । दर्शनाची तळमळ मनास । जेणे योग दर्शनास ॥ १०८ ॥
नको येऊ सज्जनगडास । रहावे बाळापुरास । पुढिल वर्षी उत्सवास । मी येईन तुझ्या स्थानास ॥ १०९ ॥
रामदास जे शब्द वदणार । ते खरे करणार । हे बाळकृष्ण जाणणार । का न आनंदीत रहाणार ? ॥ ११० ॥
दुसरा दिवस उजाडला । बाळकृष्ण परत निघाला । पुन्हा मागे पाहू लागला । पुन्हा पुन्हा करू लागला ॥ १११ ॥
आता कधी योग आपला । येणार सज्जनगडाला । चिंता रामदासाला । तारणार भक्ताला ॥ ११२ ॥
पहाता पहाता वर्ष गेले । रामदासांचे बोल कानी घुमले । दुसरे वर्ष उजाडले । उत्सव घरी करणे ठरले ॥ ११३ ॥
तयारीस सुरूवात झाली । समर्थ मूर्ती दिसू लागली । स्वारी स्वप्न रंगवू लागली । इतुकी त्यात रमली ॥ ११४ ॥
सज्जनगडाचे वातावरण । धाव घेत होते त्याचे मन । चिंतनात जाई ऐसा क्षण । कैसे रमणार घरात मन ? ॥ ११५ ॥
जो घरदार विसरतो । स्वतःस पार विसरतो । त्याच्यात रममाण होतो । तो ऐसाच वागतो ॥ ११६ ॥
रामदास नि बाळकृष्ण । नव्हते दोघे भिन्न । एकरूपता विलक्षण । ईश्वरे जाणले मनोमन ॥ ११७ ॥
ईश्वरे धाडले गजाननास । रामदासरूपे त्यांच्या घरास । दासनवमीच्या उत्सवास । मनोकामना पूर्ण करण्यास ॥ ११८ ॥
ऐसा चमत्कार घडला । दासनवमीचा दिवस उजाडला । बाळकृष्ण दासात गुंतलेला । उत्सवात तल्लीन झालेला ॥ ११९ ॥
देहाचे व्यवहार चालले । चित्त दासात गुंतलेले । सकाळचे दहा वाजले । पहाता पहाता अकरा वाजले ॥ १२० ॥
चित्त शिगेला पोहोचले । मन क्षणभर गोंधळले । पुढचे काहीच न दिसले । फक्त सज्जनगड दिसले ॥ १२१ ॥
गावकरी जमले उत्सवास । पहात होते बाळकृष्णास । त्याच्या तर्‍हेवाईकपणास । काही हसत होते त्यास ॥ १२२ ॥
त्यांना मर्म काय कळणार ? । स्वानुभवच श्रेष्ठ ठरणार । जो तोडीचा असणार । तोच सर्व समजणार ॥ १२३ ॥
पत्नी सर्व होती जाणत । देत होती साथ त्यास । ती पतीव्रता धर्मरत । नित्य पतीचे शब्द झेलत ॥ १२४ ॥
पतीव्रतेच्या पुण्याईवर । बाळकृष्णाच्या भक्तीवर । श्रद्धा तळमळीच्या जोरावर । प्रसन्न झाला परमेश्वर ॥ १२५ ॥
योग्य वेळ पाहून । तो देणार दर्शन । परमेश्वर नि बाळकृष्ण । दोघे बघत होते ऐसा क्षण ॥ १२६ ॥
वाट पहात होते त्या क्षणाची । एका सुयोगाची । दर्शनाच्या आनंदाची । एकमेका भेटण्याची ॥ १२७ ॥
जेथे परमेश्वर पाहे वाट । तो काय न करे थाट ? । असावे लागते भाग्यात । उगाच न पाहे वाट ॥ १२८ ॥
ईश्वराने महाराजांस । दिले प्रेरणेस । जावे बाळकृष्णाच्या घरास । होऊन रामदास ॥ १२९ ॥
ऐकावे लागते योग्यास । गेले बाळकृष्णाच्या घरास । ऐन दुपारच्या समयास । दर्शन द्यावयास ॥ १३० ॥
नामगजर सुरू झालेला । स्त्रिया पुरूष बाळमेळा । रामदासांचा नामघोष केला । तितक्यात सूर मिसळला ॥ १३१ ॥
“जय जय रघुवीर समर्थ” । पहाता पहाता योगी समर्थ । उभा राहिला दारात । रामदासांच्या रूपात ॥ १३२ ॥
बाळकृष्ण स्तब्ध झाला । क्षणभर गोंधळला । दाराकडे पाहू लागला । रामदास घरी पातला ॥ १३३ ॥
जवळचा गुलाल अबीर । काय उधळणार तो मूर्तीवर ? । प्रत्यक्ष रामदास असता समोर । उधळले गेले समर्थांवर ॥ १३४ ॥
घटकेत दिसे गजानन । घटकेत समर्थ चरण । गोंधळले त्याचे मन । दोन्ही रूपे बघून ॥ १३५ ॥
हुबेहुब रामदास । प्रत्यक्ष त्याचे घरास । दिलेल्या वचनास । आले पाळावयास ॥ १३६ ॥
प्रश्र्न पडला त्याच्या मनास । मानावे कुठच्या रूपास ? । पहाता झाला लीलेस । समर्थांच्या चमत्कृतीस ॥ १३७ ॥
त्या सरशी मनास आले । रामदासे मज फसविले । संभ्रमात टाकले । महाराजांस धाडले ॥ १३८ ॥
तसेच मनास पटवले । पुढिल कार्यक्रम झाले । मनातले न त्याच्या गेले । दिवसभर घोळत राहिले ॥ १३९ ॥
दिवस तसाच सरला । बाळकृष्ण थकलेला । तसाच झोपी गेला । नाना विचार करू लागला ॥ १४० ॥
झोप त्यास येईना । डोळ्यासमोरचे जाईना । घटकेत पाहे गजानना । घटकेत रामदास चरणा ॥ १४१ ॥
रामदास आले स्वप्नाला । वदले बाळकृष्णाला । ‘तो’ नि ‘मी’ नसे वेगळा । जो आला तुझ्या घराला ॥ १४२ ॥
त्याच्यातच पहाचे मला । जो मी सज्जनगडाला । तोच तुझ्या घराला । हेच ठसव मनाला ॥ १४३ ॥
‘संशयात्मा विनश्यती’ । हीच खरी ठरे उक्ती । जगता श्रद्धेवरती । सुफळे तुम्हा मिळती ॥ १४४ ॥
संशय मनीचा घालव बरा । फिरू नको सैरावैरा । त्यानेच मांडला पसारा । हा बोध जाण खरा ॥ १४५ ॥
बाळकृष्णाचे भाग्य उदयास । कारण तळमळ त्याचे मनास । तैसी तळमळ आपणास । भाग्य येईल उदयास ॥ १४६ ॥
एकोणिसशे सत्याऐंशीस । माघ वद्य नवमीस । रामदास नवमी कथेस । झाले पूर्णत्वास ॥ १४७ ॥
हा ही सुयोग घडला । तो न ठरवून केलेला । रामदास नवमीस लिहीला गेला । रामदास नवमीसच पूर्ण झाला ॥ १४८ ॥

 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य नवमोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
